import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/header";
import * as Fields from "../../sharedComponents";
import { Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { DateRangePicker } from "../../components/index";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper, Typography, Button, ButtonGroup, Checkbox, IconButton } from "@material-ui/core";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "../../common.css";
import { makeStyles } from "@material-ui/core/styles";
import * as CONFIG from "../../config/GlobalConstants";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import * as Components from "../../sharedComponents";
import DateRangepicker from "../../components/datetimepicker/datePicker";
import { useControlledValueWithTimezone } from "@mui/x-date-pickers/internals";
import MultiselectFields from "../../sharedComponents/antd/MultiselectFields";
import ValidateFields from "../../validations/validateFields";
// import { encryptData } from "../../commonFunctions.js/commonMethods";
import CryptoJS from 'crypto-js';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { number } from "prop-types";
import * as XLSX from 'xlsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { brake, dprTripsSampleFile } from "../../assets";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import './tripReconciliation.css'
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router";



const useStyles = makeStyles((theme) => ({
    heading: {
        padding: "20px",
    },
    input_box: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: "bold",
    },
    datepicker: {
        marginTop: "10px",
    },
    export_button: {
        padding: "7px",
        marginTop: "13px",
        marginRight: "15px",
        backgroundColor: "#366e93",
        color: "#ffffff",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#366e93",
        },
    },
    search_button: {
        padding: "",
        backgroundColor: "#366e93",
        color: "#ffffff",
        textTransform: "capitalize",
        marginRight: "20px",
        "&:hover": {
            backgroundColor: "#366e93",
        },
    },
    reset_button: {
        padding: "",
        backgroundColor: "#f4efeb",
        color: "#db9045",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#f4efeb",
        },
        border: " 1px solid #db9045",
    },
    header_buttons: {
        padding: "0px 15px",
    },
    alertBox: {
        padding: "10px 20px",
    },
    client_button: {
        background: "#649B42",
        textTransform: "none",
        color: "#FFFFFF",
        // marginRight: "15px",
        padding: "8px 15px",
        "&:hover": {
          background: "#649B42",
        },
      },
      list: {
        listStyle: "none",
        padding: "0px",
        margin: "0px",
      },
      list_item: {
        position: "relative",
        textAlign: "left",
        paddingLeft: "50px",
        "&::before": {
          content: '"\\25CF"',
          marginRight: "10px",
          fontSize: "22px",
          position: "absolute",
          left: "20px",
          top: "-7px",
        },
        "&:first-of-type": {
          paddingBottom: "10px",
        },
        "&:first-of-type::after": {
          content: '""',
          border: "1px dashed #707070",
          position: "absolute",
          left: "25.5px",
          height: "20px",
          top: "15px",
        },
        "&:first-of-type::before": {
          color: "#8CB369",
        },
        "&:last-of-type::before": {
          color: "red",
        },
      },
}));
const TripsViewData = (props) => {
    function getCurrentMonth() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so we add 1
        return `${year}-${month}`;
    }
    const history = useHistory()
    const currentMonth = getCurrentMonth();
    const vehicleTypeFilterField = {
        vehicleType: {
            name: "vehicleType",
            label: "Vehicle Type",
            options: [
                { label: "All Vehicles", value: "allVehicles" },
                { label: "Own Vehicles", value: "ownVehicles" },
                { label: "Market Vehicles", value: "marketVehicles" },
            ],
            value: { label: "All Vehicles", value: "allVehicles" },
            topLabel: true,
            placeholder: "Select Vehicle Type",
        },
    }
    let filterFields = {

        // vehicleslist: {
        //     name: "vehicleslist",
        //     label: "Vehicles List",
        //     value: { label: "", value: "" },
        //     // label: "Registration",
        //     options: [],
        //     topLabel: true,
        //     isValid: true,
        //     validationRequired: true,
        //     errorMsg: "Please select vehicle",
        //     validPattern: 'ALPHABET_with_numeric_label',

        // },
        vehicleslist: {
            name: "vehicleslist",
            label: "Vehicles List",
            options: [],
            value: [],
            actualValue: [],
            data: [],
            topLabel: true,
            // placeholder: "Select Vehicle",
            // validationRequired: true,
            // errorMsg: "Please select vehicle",
            // validPattern: 'ALPHABET_with_numeric_label',
        },
        // pickupSite: {
        //     name: "pickupSite",
        //     label: "Pickup Site",
        //     value: [],
        //     topLabel: true,
        //     options: [],
        // },
        // dropoffSite: {
        //     name: "dropoffSite",
        //     label: "Dropoff Site",
        //     value: [],
        //     topLabel: true,
        //     options: [],
        // },
        // truckType: {
        //     name: "truckType",
        //     label: "Truck Type",
        //     value: { label: "", value: "" },
        //     topLabel: true,
        //     options: [{ label: "Loader", value: "Loader" }],
        // },
        clientId: {
            name: "clientId",
            isValid: true,
            value: { label: "", value: "" },
            label: "Client Name",
            options: [],
            topLabel: true,
            // validationRequired: true,
            errorMsg: "Please select client",
            // validPattern : 'ALPHABET_with_numeric_label',
        },
        routesList: {
            name: "routesList",
            label: "Routes List",
            options: [],
            value: [],
            actualValue: [],
            data: [],
            topLabel: true,
            // placeholder: "Select Vehicle",
            // validationRequired: true,
            // errorMsg: "Please select vehicle",
            // validPattern: 'ALPHABET_with_numeric_label',
        },
        routeId: {
            name: "routeId",
            isValid: true,
            value: { label: "", value: "" },
            label: "CRN-Route Name",
            options: [],
            topLabel: true,
            // validationRequired: true,
            // errorMsg: "Please select route",
            // validPattern: 'ALPHABET_with_numeric_label',
            // contractId: [],
        },
        date: {
            name: "date",
            label: "Date",
            value: moment(new Date()),
            topLabel: true,
            isValid: true,
            validationRequired: true,
            validPattern: "DATE",
            errorMsg: "Please select date",
            maxDate: new Date(),
            disabledDate: (d) => !d || d.isAfter(new Date()),
        },
        // ReportType: {
        //   name: "ReportType",
        //   label: "Report Type",
        //   value: { label: "", value: "" },
        //   topLabel: true,
        //   options: [{ label: "Trip Summary Report", value: "Loader" },{ label: "Trip Detail Report", value: "Loader" },{ label: "Availability and Utilization Report", value: "Loader" },
        //   { label: "Resource Summary Report", value: "Loader" },{ label: "Purchase Cost Support Equipment Report", value: "Loader" },{ label: "Availability and Utilization Summary Report", value: "Loader" },{ label: "Availability and Utilization Detail Summary Report", value: "Loader" },
        //   { label: "Equipment Wise Production Details Report", value: "Loader" },{ label: "Equipment Fuel Consumption Report", value: "Loader" },{ label: "Utility Equipment fuel Consumption Report", value: "Loader" },
        //   { label: "Admin Vehicle Equipment fuel Consumption Report", value: "Loader" },{ label: "Market Vehicle Equipment fuel Consumption Report", value: "Loader" },{ label: "Fuel Purchase Report", value: "Loader" },{ label: "Fuel Obnormality Report", value: "Loader" },
        //   { label: "Repair and Maintenance Cost Details Report", value: "Loader" },{ label: "Repair and Maintenance Cost Summary Report", value: "Loader" },{ label: "Major Repairs Report", value: "Loader" },{ label: "DEF AND HSD Costing Report", value: "Loader" },
        //   { label: "Operatior and Driver Requirements Report", value: "Loader" },{ label: "Compliance Report", value: "Loader" }],
        // },
    };

    const [optionsList, setOptionsList] = useState([]);
    const [dropoffList, setDropoffList] = useState([]);
    const [pickupList, setPickupList] = useState([]);
    const Service = global.service

    const columns = [
        { id: "id", disablePadding: true, label: "S.NO", checked: true },
        {
            id: "vehicle",
            disablePadding: true,
            label: "VEHICLE",
            checked: true,
        },
        {
            id: "tripDate",
            disablePadding: true,
            label: "Pickup Entry & Exit",
            checked: true,
        },
        {
            id: "dropOffDate",
            disablePadding: true,
            label: "Dropoff Entry & Exit",
            checked: true,
        },
        // {
        //     id: "routeName",
        //     disablePadding: true,
        //     label: "ROUTE NAME",
        //     checked: true,
        // },
        {
            id: "routeName",
            disablePadding: true,
            label: "ROUTE NAME",
            checked: true,
        },
        {
            id: "ownerCompanyName",
            disablePadding: true,
            label: "OWNER COMPANY NAME",
            checked: true,
        },
        {
            id: "clientCompanyName",
            disablePadding: true,
            label: "CLIENT COMPANY NAME",
            checked: true,
        },
        {
            id: "actions",
            disablePadding: true,
            label: "ACTIONS",
            checked: true,
        },
        // {
        //     id: "clientCompanyName",
        //     disablePadding: true,
        //     label: "CLIENT COMPANY NAME",
        //     checked: true,
        // },
        // { id: "loader", disablePadding: true, label: "LOADER", checked: true },
        // {
        //     id: "loaderTime",
        //     disablePadding: true,
        //     label: "LOADER DATE & TIME",
        //     checked: true,
        // },
        // {
        //     id: "capacity",
        //     disablePadding: true,
        //     label: "CAPACITY",
        //     checked: true,
        // },
        // {
        //     id: "pickup_Site",
        //     disablePadding: true,
        //     label: "PICKUP SITE",
        //     checked: true,
        // },
        // {
        //     id: "pickupEntryDateTime",
        //     disablePadding: true,
        //     label: "PICKUP ENTRY DATE & TIME",
        //     checked: true,
        // },
        // {
        //     id: "pickupExitDateTime",
        //     disablePadding: true,
        //     label: "PICKUP EXIT DATE & TIME",
        //     checked: true,
        // },
        // {
        //     id: "loadingTime",
        //     disablePadding: true,
        //     label: "LOADING TIME",
        //     checked: true,
        // },
        // {
        //     id: "dropOff_Site",
        //     disablePadding: true,
        //     label: "DROPOFF SITE",
        //     checked: true,
        // },
        // {
        //     id: "dropoffEntryDateTime",
        //     disablePadding: true,
        //     label: "DROPOFF ENTRY DATE & TIME",
        //     checked: true,
        // },
        // {
        //     id: "dropoffExitDateTime",
        //     disablePadding: true,
        //     label: "DROPOFF EXIT DATE & TIME",
        //     checked: true,
        // },
        // {
        //     id: "unloadingTime",
        //     disablePadding: true,
        //     label: "UNLOADING TIME",
        //     checked: true,
        // },
        // {
        //     id: "tat",
        //     disablePadding: true,
        //     label: "TAT",
        //     checked: true,
        // },
    ];
    const [fields, setFields] = useState(_.cloneDeep(filterFields));
    const [vehicleTypeField, setVehicleTypeField] = useState(_.cloneDeep(vehicleTypeFilterField))
    const [buttonSpinner,setButtonSpinner] = useState(false)
    const [fromDateToHist, setFromDateToHist] = React.useState(
        moment().format("YYYY-MM-DD")
    );
    const [toDateToHist, setToDateToHist] = React.useState(
        moment().format("YYYY-MM-DD")
    );
    const [dateRangeValue, setDateRangeValue] = React.useState([
        moment().subtract(0, "days").startOf("day"),
        moment().endOf("day"),
    ]);
    const [vehicles, setVehicles] = useState([]);
    const [routes, setRoutes] = useState([])
    const [total, setTotal] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState(null);
    const [loader, setLoader] = useState(false);
    const [csvData, setCsvData] = useState([]);
    // const [, forceUpdate] = useState();
    const [headRows, setHeadRows] = useState(columns);
    const [cols, setCols] = useState(columns);
    const [searchData, setSearchData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        message: "",
    });

    const [paramsData, setParamData] = useState({
        allVehicles: true,
        marketVehicles: true,
        ownVehicles: true,
    });




    const fieldsRef = useRef(fields);
    let csvlink = useRef();
    const classes = useStyles();
    const [selected, setSelected] = React.useState([]);
    //   const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [cancelled,setcancelled] = useState(false)

    const autoCompleteChangeHandler = async (value, name) => {
        let newFields = _.cloneDeep(fields);
        let newVehicleTypeFields = _.cloneDeep(vehicleTypeField)
        if (name != 'vehicleType') {
            if (newFields[name]["validationRequired"] && value.label.length > 0) {
                newFields[name]["isValid"] = true;
            }
        }

        if (name == "vehicleType" && value?.value != "") {
            if (value.value == "marketVehicles") {
                VehiclesList({ allVehicles: true, marketVehicles: true });
            } else if (value.value == "ownVehicles") {
                VehiclesList({ allVehicles: true, ownVehicles: true });
            } else {
                VehiclesList({
                    allVehicles: true,
                    marketVehicles: true,
                    ownVehicles: true,
                });
            }
            newVehicleTypeFields[name]["value"] = value;
            setVehicleTypeField(newVehicleTypeFields);
        } else if (name === "clientId") {
            // tripLocationRef.current.setRoute({});

            await Service.getRoutesForCreateTrip(value.value).then((res) => {
                newFields["routeId"]["value"] = { label: "", value: "" };
                // newFields["deliveryOrder"]["value"] = { label: "", value: "" };
                newFields["routeId"]["options"] = res.routes.map((route) => {
                    return {
                        label: route.routeName,
                        value: route.id,
                    };
                });
                let routeOptions = res.routes.map((route) => {
                    return {
                        name: route.routeName,
                        id: route.id,
                    };
                });
                setRoutes([{ id: "all", name: "Select All" }, ...routeOptions]);
            });
            
            // setFields(newFields);
        }
        newFields[name]["value"] = value;
        setFields(newFields);

        // let newFields = _.cloneDeep(fields);
        // if (value) {
        //     fields[name]["value"] = value;
        //     newFields[name]["isValid"] = true;
        //     if (newFields[name]["validationRequired"] && value == null) {
        //       newFields[name]["isValid"] = false; 
        //     }
        //     fields["vehicleslist"]["actualValue"] = [];
        //     if (name === "vehicleType") {
        //         if (value.value == "marketVehicles") {
        //             VehiclesList({ allVehicles: true, marketVehicles: true });
        //         } else if (value.value == "ownVehicles") {
        //             VehiclesList({ allVehicles: true, ownVehicles: true });
        //         } else {
        //             VehiclesList({
        //                 allVehicles: true,
        //                 marketVehicles: true,
        //                 ownVehicles: true,
        //             });
        //         }
        //     } else if (name === "clientId") {
        //         // tripLocationRef.current.setRoute({});

        //         await Service.getRoutesForCreateTrip(value.value).then((res) => {
        //             newFields["routeId"]["value"] = { label: "", value: "" };
        //             // newFields["deliveryOrder"]["value"] = { label: "", value: "" };
        //             newFields["routeId"]["options"] = res.routes.map((route) => {
        //                 return {
        //                     label: route.routeName,
        //                     value: route.id,
        //                 };
        //             });
        //         });
        //         setFields(newFields);
        //     } else {
        //         newFields[name]["value"] = value;
        //         setFields(newFields);
        //     }
        // }
    };

    const encryptData = (data, secretKey) => {
        return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    };

    // Function to store encrypted data in sessionStorage
    const storeData = (key, data, secretKey) => {
        const encryptedData = encryptData(data, secretKey);
        sessionStorage.setItem(key, encryptedData);
    };

    // Add this state to manage selected rows
    // Add this state to manage selected rows
    const [selectedRows, setSelectedRows] = useState([]);

    // Checkbox handler
    const [tripsData,setTripsData] = useState([])

    const searchHandler = (columns) => {
        let newFields = _.cloneDeep(fields);
        setLoader(true);
        
        let newObject = {
            vehicleId : fields.vehicleslist?.value,
            routeId : fields.routesList?.value
        }
        // let newObject ={"routeId":"2820","vehicleId":"4962,3647"}

        Service.onDemandTripsFiltringData(newObject).then((res) => {
            
            if (res?.status === true) {
                setLoader(true)
                setTripsData(res.data)
                let checkedCols = columns.filter((col) => col.checked === true);
                setHeadRows(checkedCols);

                let data = res?.data?.map((report, index) => {
                    const isChecked = selectedRows.includes(index);
                    report.id = index + 1
                    return {
                        // select: (
                        //     <Checkbox
                        //     checked={isChecked}
                        //     onChange={() => onCheckboxChange(index)} // Use index to toggle selection
                        // />
                        // ),
                        ...(checkedCols.find((data) => data.id === "id")
                            ? {
                                id: index + 1
                            }
                            : {}),
                        ...(checkedCols.find((data) => data.id === "id")
                            ? {
                                id: (
                                    <div className={classes.table_data}>
                                        {index + 1}
                                    </div>
                                ),
                            }
                            : {}),
                        ...(checkedCols.find((data) => data.id === "vehicle")
                            ? {
                                vehicle: (
                                    <div 
                                    className={classes.table_data} 
                                    style={{
                                      backgroundColor: '#FCB704', 
                                      border: '1px solid black', // Corrected border syntax
                                      color: 'black', 
                                      padding: '2px',
                                      borderRadius:'3px'
                                    }}
                                  >
                                    {report?.regiNo || "--"} {/* Simplified conditional rendering */}
                                  </div>
                                ),
                            }
                            : {}),
                        ...(checkedCols.find((data) => data.id === "tripDate")
                            ? {
                                tripDate: (
                                //     <div>
                                //     <div className={classes.table_data}>
                                //         {report?.pickup.entryDateTime ? report?.pickup.entryDateTime : "--"}
                                //     </div>
                                //     <div className={classes.table_data}>
                                //     {report?.pickup.exitDateTime ? report?.pickup.exitDateTime : "--"}
                                // </div>
                                // </div>
                                <ul className={classes.list}>
                                <li className={classes.list_item}>
                                {report?.pickup.entryDateTime ? report?.pickup.entryDateTime : "--"}
                                </li>
                                <li className={classes.list_item}>
                                {report?.pickup.exitDateTime ? report?.pickup.exitDateTime : "--"}
                                </li>
                              </ul>
                                ),
                            }
                            : {}),
                            ...(checkedCols.find((data) => data.id === "dropOffDate")
                            ? {
                                dropOffDate: (
                                    <ul className={classes.list}>
                                    <li className={classes.list_item}>
                                    {report?.dropoff.entryDateTime ? report?.dropoff.entryDateTime : "--"}
                                    </li>
                                    <li className={classes.list_item}>
                                    {report?.dropoff.exitDateTime ? report?.dropoff.exitDateTime : "--"}
                                    </li>
                                  </ul>
                                   
                                ),
                            }
                            : {}),
                            
                        ...(checkedCols.find((data) => data.id === "routeName")
                            ? {
                                routeName: (
                                    <ul className={classes.list}>
                                    <li className={classes.list_item}>
                                    {report?.routeName ? report?.routeName.split("-")[0] : "--"}
                                    </li>
                                    <li className={classes.list_item}>
                                    {report?.routeName ? report?.routeName.split("-")[1] : "--"}
                                    </li>
                                  </ul>
                                    // <div className={classes.table_data}>
                                    //     {report?.routeName ? report?.routeName : "--"}
                                    // </div>
                                ),
                            }
                            : {}),
                        ...(checkedCols.find((data) => data.id === "ownerCompanyName")
                            ? {
                                ownerCompanyName: (
                                    <div className={classes.table_data}>
                                        {report?.companyName
                                            ? report?.companyName
                                            : "--"}
                                    </div>
                                ),
                            }
                            : {}),
                        ...(checkedCols.find((data) => data.id === "clientCompanyName")
                            ? {
                                clientCompanyName: (
                                    <div className={classes.table_data}>
                                        {report?.client?.companyName
                                            ? report?.client?.companyName
                                            : "--"}
                                    </div>
                                ),
                            }
                            : {}),
                        // Add checkbox to select the row
                        actions: (
                            <div>
                                <Components.DropDownMenu
                                    options={[{ label: "View Details", type: 'button' }]}
                                    data={report}
                                    menuStyle={{ textAlign: "center" }}
                                    buttonClickHandler={(val) => {
                                        storeData('reconcillation', report, "trip_reconcillation")
                                        const windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=800,height=600";
                                        window.open(`/tripReconciliation`, "_blank", windowFeatures);
                                    }}
                                />
                            </div>
                        ),
                    };
                });

                setTableData(data);
                setLoader(false);
                setSearchData(res);
                setAlertData({
                    open: false,
                    severity: "",
                    message: "",
                });
                setGetResponse(res.data);
            } else {
                setSearchData([]);
                setTableData([]);
                setLoader(false);
                setAlertData({
                    open: true,
                    severity: "error",
                    message: res?.message,
                });
            }
            setLoader(false);
        }).catch((error) => {
            console.log(error);
            setLoader(false);
            setAlertData({
                open: true,
                severity: CONFIG.ALERT_SEVERITY.error,
                message:
                    error.response && error.response.data
                        ? error.response.data.message
                        : "Something went wrong!",
            });
        });
    };

    const VehiclesList = (data) => {
        // let promise1 = global.service.getSites({ onlyMeta: true });
        let promise1 = global.service.vehicleslistByType(data);
        const promise2 = Service.getClients({
            onlyMeta: true,
        });
        // let promise2 = global.service.getSites({ siteTypeId: 4 });
        // let promise3 = global.service.getSites({ siteTypeId: 5 });

        Promise.all([promise1, promise2])
            .then((res) => {
                // if(res && res){
                let newFields = _.cloneDeep(fields);
                let options = res[0].vehicles
                    .filter((item) => item.flespiId !== null)
                    .map((item) => ({ label: item.registrationNumber, value: item.id }));
                // options.unshift({label: 'All Vehicles', value: 'All Vehicles'})

                let listOptions = res[0].vehicles
                    .filter((item) => item.flespiId !== null)
                    .map((item) => ({ id: item.id, name: item.registrationNumber }));
                setVehicles([{ id: "all", name: "Select All" }, ...listOptions]);
                // listOptions.unshift({id: 'All Vehicles', item: 'All Vehicles'})

                newFields["vehicleslist"]["options"] = options;

                newFields["clientId"]["options"] = res[1].clients.map((clients) => {
                    return { label: `${clients.companyName}`, value: `${clients.id}` };
                });

                setOptionsList(listOptions);
                // setDropoffList(dropoffOptions);
                // setPickupList(pickupOptions);
                setFields(newFields);
                // }
            })
            .catch((error) => {
                console.log(error);
                setAlertData({
                    open: true,
                    severity: CONFIG.ALERT_SEVERITY.error,
                    message:
                        error.response && error.response.data
                            ? error.response.data.message
                            : "Something went wrong!",
                });
            });
    };
    useEffect(() => {
        fieldsRef.current = fields;
    }, [fields]);

    // const vehicleSelectHandler = (value, name) => {
    //     let newFields = _.cloneDeep(fields);
    //     if (newFields[name]["validationRequired"] && value !== "") {
    //         newFields[name]["isValid"] = true;
    //     }
    //     newFields[name]["value"] = value.join(",");
    //     let data = [];
    //     let filterOptions = fields?.vehicleslist?.options?.filter((i) =>
    //         value.map((j) => {
    //             if (j === i.id) {
    //                 return data.push(i);
    //             }
    //         })
    //     );
    //     newFields[name]["actualValue"] = value;
    //     newFields[name]["data"] = data;
    //     setFields(newFields);
    // };

    const vehicleSelectHandler = (value, name) => {
        let newFilters = _.cloneDeep(fields);
        if (value.includes("all")) {
            if (newFilters[name]["actualValue"].length === vehicles.length - 1) {
                newFilters[name]["value"] = "";
                newFilters[name]["actualValue"] = [];
                let newVehicles = _.cloneDeep(vehicles);
                newVehicles.splice(0, 1, { id: "all", name: "Select All" });
                setFields(newFilters);
                setVehicles(newVehicles);
            } else {
                newFilters[name]["value"] = vehicles
                    .filter((veh) => veh.id !== "all")
                    .map((veh) => veh.id)
                    .join(",");
                newFilters[name]["actualValue"] = vehicles
                    .filter((veh) => veh.id !== "all")
                    .map((veh) => `${veh.id}`);
                let newVehicles = _.cloneDeep(vehicles);
                newVehicles.splice(0, 1, { id: "all", name: "Clear" });
                setFields(newFilters);
                setVehicles(newVehicles);
            }
        } else {
            newFilters[name]["value"] = value.join(",");
            if (newFilters[name]["actualValue"].length === vehicles.length - 1) {
                let newVehicles = _.cloneDeep(vehicles);
                newVehicles.splice(0, 1, { id: "all", name: "Select All" });
                setVehicles(newVehicles);
            }
            newFilters[name]["actualValue"] = value;
            setFields(newFilters);
        }
    };
    const routeSelectHandler = (value, name) => {
        let newFilters = _.cloneDeep(fields);
        if (value.includes("all")) {
            if (newFilters[name]["actualValue"].length === routes.length - 1) {
                newFilters[name]["value"] = "";
                newFilters[name]["actualValue"] = [];
                let newVehicles = _.cloneDeep(routes);
                newVehicles.splice(0, 1, { id: "all", name: "Select All" });
                setFields(newFilters);
                setRoutes(newVehicles);
            } else {
                newFilters[name]["value"] = routes
                    .filter((veh) => veh.id !== "all")
                    .map((veh) => veh.id)
                    .join(",");
                newFilters[name]["actualValue"] = routes
                    .filter((veh) => veh.id !== "all")
                    .map((veh) => `${veh.id}`);
                let newVehicles = _.cloneDeep(routes);
                newVehicles.splice(0, 1, { id: "all", name: "Clear" });
                setFields(newFilters);
                setRoutes(newVehicles);
            }
        } else {
            newFilters[name]["value"] = value.join(",");
            if (newFilters[name]["actualValue"].length === routes.length - 1) {
                let newVehicles = _.cloneDeep(routes);
                newVehicles.splice(0, 1, { id: "all", name: "Select All" });
                setRoutes(newVehicles);
            }
            newFilters[name]["actualValue"] = value;
            setFields(newFilters);
        }
    };

    const clearHandler = () => {
        window.location.reload();
    };
    const closeAlert = () => {
        let alert = _.cloneDeep(alertData);
        alert.open = false;
        alert.severity = "";
        alert.message = "";
        setAlertData(alert);
    };
    let validateFields = new ValidateFields();
    const onClickHandler = async () => {


        // Convert to UTC timestamp
        // searchHandler(cols)

        let dataStatus = await validateFields.validateFieldsData(fields);
        if (dataStatus.status) {

            searchHandler(cols)

        } else {
            let newFields = _.cloneDeep(fields);
            let erroredFields = Object.keys(dataStatus.data).filter(
                (key, i) => dataStatus.data[key].isValid === false
            );
            newFields = dataStatus.data;
            setFields(newFields);
        }

    }

    useEffect(() => {
        VehiclesList(paramsData);
        // searchHandler(cols);

        // getSupervisorList();
    }, []);
    const [getResponse, setGetResponse] = useState()


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = getResponse.map((response) => {
                return {
                    id: response?.id
                    // vehicle: response?.vehicle?.props?.children,
                    // tripDate: response?.tripDate?.props?.children,
                    // routeName: response?.routeName?.props?.children,
                    // ownerCompanyName: response?.ownerCompanyName?.props?.children,
                    // clientCompanyName: response?.clientCompanyName?.props?.children,

                }
            });
            // newSelected.push({
            //   id: response.id,
            //   companyName: response.companyName,
            //   balance: response.balance.props.children[1],
            //   vendorName: response.vendorName,
            //   tdsPercentage: 0,
            //   tdsAmount: 0,
            //   fileAttachment: null,
            // })
            setSelectedRows(newSelected);
            setSelected(newSelected);
            setcancelled(false)
            return;
        }
        setSelectedRows([]);
        setSelected([]);
        setcancelled(false)
    };

    const timeStampChangeHandler = (value, name) => {
        let newFields = _.cloneDeep(fields);
        newFields[name]["value"] = value;
        // newFields["devicePurDate"]["disabledDate"] = (d) => !d || d.isBefore(value);
        // if(name == 'devicePurDate'){
        //   newFields["installDate"]["disabledDate"] = (d) => !d || d.isBefore(value);
        // }
        newFields[name]["isValid"] = true;
        if (newFields[name]["validationRequired"] && value == null) {
            newFields[name]["isValid"] = false;
        }
        setFields(newFields);
    };

    const handleClick = (event, response) => {
        const selectedIndex = selected.findIndex((due) => Number(due.id) === Number(response.id.props?.children));
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, response);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        let newSelectedRows = [...selectedRows];
        const isExistedRow = selectedRows.find((i) => Number(i.id) === Number(response.id.props?.children));
        if (isExistedRow) {
            newSelectedRows = newSelectedRows.filter((i) => Number(i.id) !== Number(response.id.props?.children));
        } else {
            newSelectedRows.push({
                id: response?.id?.props?.children,
                vehicle: response?.vehicle?.props?.children,
                tripDate: response?.tripDate?.props?.children,
                routeName: response?.routeName?.props?.children,
                ownerCompanyName: response?.ownerCompanyName?.props?.children,
                clientCompanyName: response?.clientCompanyName?.props?.children,
            });
        }
        setSelectedRows(newSelectedRows);
        setSelected(newSelected);
        setcancelled(false);
    };
    // let cancelled = false;
    const [inProgress, setInProgress] = useState(0);


const cancelledRef = useRef(cancelled);
useEffect(() => {
    cancelledRef.current = cancelled;
}, [cancelled]);

// Define the onCancel function
const onCancel = () => {
    setcancelled(true); // Set the flag to true to stop further API calls
    setShowAnimation(false);
    setInProgress(0); // Optionally reset the progress count
    setLoadingComplete(false);
    setSelected([])
    setSelectedRows([])
    closeAlert()
    // setShowModal(false); // Close the modal
};
//    let addedTrips = 10
    const [isAddingTrips, setIsAddingTrips] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showAnimation, setShowAnimation] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            backdropFilter: 'blur(5px)', // Apply blur effect
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999, // Ensures it appears above other content
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            padding: '20px',
            minWidth: '400px',
            minHeight: '200px',
        },
        title: {
            marginBottom: 10,
        },
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '10px',
        },
        startCount: {
            color: 'lightblue',
            marginRight: '10px',
        },
        endCount: {
            color: '#366E93',
            marginLeft: '5px',
            fontWeight: 'bold'
        },
        progressBar: {
            position: 'relative',
            width: '100%',
            height: '20px',
            backgroundColor: '#e0e0e0',
            borderRadius: '5px',
            overflow: 'hidden',
            margin: '0 10px',
        },
        progress: {
            height: '100%',
            backgroundColor: '#366E93',
            transition: 'width 0.3s ease',
        },
        truckIcon: {
            position: 'absolute',
            bottom: '60%',
            transform: 'translateY(50%)', // Center the truck icon vertically
            width: '25px',
            height: '25px',
            transition: 'left 0.3s ease', // Smooth transition for the truck movement
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        truckImage: {
            width: '100%',
            height: '100%',
        },
        truckCount: {
            position: 'absolute',
            bottom: '0px', // Position it above the truck
            left: '30%',
            transform: 'translateX(-50%)',
            color: 'white',
            fontSize: '10px',
            fontWeight: 'bold',
            // backgroundColor: 'rgba(0, 0, 0, 0.7)', // Optional: add a background for better visibility
            // borderRadius: '4px', // Optional: round corners
            padding: '1px 2px', // Optional: some padding
            zIndex: 1, // Ensures it appears above the truck icon
        },
        successMessage: {
            marginTop: '10px',
            color: 'green',
            fontWeight: 'bold',
        },
    };

    const [totalTrips,setTotalTrips] = useState(0)
    
    const addTripsFunction = async()=>{
        // setIsAddingTrips(true);
        // setProgress(0);
        setInProgress(0);
        setShowAnimation(true);
        setTotalTrips(selectedRows.length)
        let successfulTrips = 0;
        if(selectedRows.length == 0){
            setShowAnimation(false)
            setIsAddingTrips(false);
            setButtonSpinner(false)
            setAlertData({
                open: true,
                severity: CONFIG.ALERT_SEVERITY.error,
                message: "Please select atleast one trip",
            })
        if (cancelledRef.current) return; // Stop processing if cancelled

       
        // let selectedTrips = []
        setButtonSpinner(true)
       
        
        }else{
            let failed = false;
            // selectedTrips = selectedRows.map(async (each)=>{
            for (const each of selectedRows) {
                if (failed || cancelledRef.current) {
                    break;
                }
                let index = each.id - 1
                let onDemandTrips = tripsData[index]
                let tripsObject = {
                    "truckId": Number(onDemandTrips.truckId),
                    "clientId": Number(onDemandTrips.client.id),
                    "contractId": Number(onDemandTrips.contract.id),
                    "regiNum": onDemandTrips.regiNo,
                    "flespiId": onDemandTrips.flespiId,
                    "bookingTypeName":'TBB',
                    "routeId": Number(onDemandTrips.routeId),
                    "fromAddress": onDemandTrips.pickup.address,
                    "toAddress": onDemandTrips.dropoff.address,
                    "fromLat": onDemandTrips.pickup.latitude,
                    "fromLong": onDemandTrips.pickup.longitude,
                    "toLat": onDemandTrips.dropoff.latitude,
                    "toLong": onDemandTrips.dropoff.longitude,
                    "pickupSiteId":onDemandTrips.pickup.site._id,
                    "dropoffSiteId": onDemandTrips.dropoff.site._id,
                    "pickupName": onDemandTrips.pickup.name,
                    "dropoffName": onDemandTrips.dropoff.name,
                    "polyline": onDemandTrips.routePolyline,
                    "vehicleType": onDemandTrips.vehicleType,
                    "routeName": onDemandTrips.routeName,
                    // "maximumMarketFreightAmount": onDemandTrips.maxMarketFreightAmount,
                    "freightAmount": onDemandTrips.vehicleType == 'MARKET' ? onDemandTrips.maxMarketFreightAmount : onDemandTrips.freightAmount,
                    "startDateTime": moment(onDemandTrips.tripDate, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "unloadDateTime": moment(onDemandTrips.dropoff.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "pickupEntryLat": onDemandTrips.pickup.latitude,
                    "pickupExitLong": onDemandTrips.pickup.latitude,
                    "entryDateAndTime": moment(onDemandTrips.pickup.entryDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "exitDateAndTime": moment(onDemandTrips.pickup.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "entryOdo": Number(onDemandTrips.pickup.entryOdo),
                    "dropoffEntryLat": onDemandTrips.dropoff.latitude,
                    "dropoffExitLong": onDemandTrips.dropoff.longitude,
                    "dropoffEntryDateAndTime": moment(onDemandTrips.dropoff.entryDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "dropoffExitDateAndTime": moment(onDemandTrips.dropoff.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "dropoffOdo": onDemandTrips.dropoff.entryOdo,
                    "tripStart": moment(onDemandTrips.tripDate, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "tripEnd":moment(onDemandTrips.dropoff.exitDateTime, 'MMM DD, YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    commodityTypeId : Number(onDemandTrips.commodity.id),
                    maxDistance : onDemandTrips.estimatedDistance ? Number(onDemandTrips.estimatedDistance) : 0,
                    pickupHoldingTime : onDemandTrips.routePickupHoldingTime ? Number(onDemandTrips.routePickupHoldingTime) : 0,
                    dropoffHoldingTime : onDemandTrips.routeDropoffHoldingTime ? Number(onDemandTrips.routeDropoffHoldingTime) : 0,
                    // transitTime : onDemandTrips.transiteTime ? (parseInt(onDemandTrips.transiteTime.match(/(\d+)H/)[1]) * 60) + parseInt(onDemandTrips.transiteTime.match(/(\d+)M/)[1]) : 0,
                    // estimatedTat : onDemandTrips.estimatedTat ? Number(onDemandTrips.estimatedTat) : 0
                }
                if (cancelledRef.current) {
                    break;
                }
              
                
                     await Service.addOndemandTripsData([tripsObject]).then((res) => {
                        if (res.status === true) {
                            setInProgress(prev => prev + 1);
                            successfulTrips += 1;
                            // Check if all trips are processed
                            // if (inProgress === totalTrips) {
                            //     setLoadingComplete(true);
                            //     setAlertData({
                            //         open: true,
                            //         message: res.message,
                            //         severity: "success",
                            //     });
                            // }

                
                            setSpinner(false); 
                            // setButtonSpinner(false);
                            setIsAddingTrips(false);
                        } else {
                            setShowAnimation(false);
                            setAlertData({
                                open: true,
                                message: res.message,
                                severity: "error",
                            });
                            setSpinner(false);
                            setButtonSpinner(false);
                            failed = true;
                        }
                    });
                
                // return tripsObject
            }

            if (successfulTrips == selectedRows.length) {
                setLoadingComplete(true);
                setAlertData({
                  open: true,
                  message: 'All trips have been successfully added.',
                  severity: 'success',
                });
              }
        }
       
        // if(selectedTrips.length > 0){
            // const interval = setInterval(() => {
            //     setProgress((prev) => {
            //       if (prev >= 100) {
            //         clearInterval(interval);
            //         setIsAddingTrips(false); // Trips added
            //         return 100;
            //       }
            //       return prev + 10; // Increment progress
            //     });
            //   }, 100);
           
           
        // }
            
    }


    // useEffect(() => {
        // const interval = setInterval(() => {
        //   if (inProgress < totalTrips) {
        //     setInProgress(prev => prev + 1);
        //   } else {
        //     clearInterval(interval);
        //     setLoadingComplete(true);
        //   }
        // }, 1000); 
    
        // return () => clearInterval(interval);
    //   }, [inProgress, totalTrips]);
    // useEffect(() => {
    //     if (progress === 100) {
    //       // Hide animation after completion
    //       const timeout = setTimeout(() => {
    //         setShowAnimation(false);
    //       }, 2000); // Wait for 2 seconds before hiding the animation
    
    //       return () => clearTimeout(timeout);
    //     }
    //   }, [progress]);
    // const handleClick = (event, response) => {
    //     const selectedIndex = selected.findIndex((due) => Number(due.id) === Number(response.id));
    //     let newSelected = [];
    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, response);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1)
    //         );
    //     }
    //     let newSelectedRows = [...selectedRows];
    //     const isExistedRow = selectedRows.find((i) => Number(i.id) === Number(response.id));
    //     if (isExistedRow) {
    //         newSelectedRows = newSelectedRows.filter((i) => Number(i.id) !== Number(response.id));
    //     } else {
    //         newSelectedRows.push({
    //             id: response?.id

    //         });
    //     }
    //     setSelectedRows(newSelectedRows);
    //     setSelected(newSelected);
    // };


    // console.log(tableData, `tableData`)
   
    const [excelData, setExcelData] = useState([]);
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
      // Trigger click on the hidden file input
      fileInputRef.current.click();
    };
    const [newFile, setFile] = useState(null);

    
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
      
        if (file) {
          setFile(file);
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
      
            // Assuming data is in the first sheet
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
      
            // Convert sheet data to JSON
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
            // Arrange data into an array of CreateDprOrderDto objects
            const headers = jsonData[0];
            const rows = jsonData.slice(1).map(row => {
              const rowObject = {}; // Create a new instance of the DTO
              headers.forEach((header, index) => {
                const headerKey = header.trim().replace(/\s+/g, ''); // Remove all spaces
                switch (headerKey) {
                    //   case 'S.No.':
                    //     rowObject.serialNo = row[index] || null;
                    //     break;
                  case 'SAPGPNo':
                    rowObject.sapGpNo = row[index] || null;
                    break;
                  case 'SecurityInw.No.':
                    rowObject.securityInwNo = row[index] || null;
                    break;
                  case 'ULDate':
                    if (typeof row[index] === 'number') {
                        rowObject.ulDate = new Date((row[index] - 25569) * 86400 * 1000);
                      } else if (typeof row[index] === 'string') {
                        // Parse the date string (MM/DD/YYYY)
                        const parts = row[index].split('/');
                        // Adjust the month (subtract 1 since months are 0-indexed in JavaScript)
                        rowObject.ulDate = new Date(parts[2], parts[0] - 1, parts[1]);
                      } else {
                        rowObject.ulDate = null; // Handle case where row[index] is neither
                      }
                      break;
                  case 'MaterialName':
                    rowObject.materialName = row[index] || null;
                    break;
                  case 'SupplierName':
                    rowObject.supplierName = row[index] || null;
                    break;
                  case 'VehicleNumber':
                    rowObject.vehicleNumber = row[index] || null;
                    break;
                  case 'TransporterName':
                    rowObject.transporterName = row[index] || null;
                    break;
                  case 'InvoiceNo.':
                    rowObject.invoiceNo = row[index]; // Invoice number is required
                    break;
                  case 'SupplierGrossWT':
                    rowObject.supplierGrossWt = row[index] || null;
                    break;
                  case 'SupplierTareWT':
                    rowObject.supplierTareWt = row[index] || null;
                    break;
                  case 'SupplierNetWT':
                    rowObject.supplierNetWt = row[index] || null;
                    break;
                  case 'WBTicketNo.':
                    rowObject.wbTicketNo = row[index] || null;
                    break;
                  case 'MEILGrossWT':
                    rowObject.meilGrossWt = row[index] || null;
                    break;
                  case 'MEILTareWT':
                    rowObject.meilTareWt = row[index] || null;
                    break;
                  case 'MEILNetWT':
                    rowObject.meilNetWt = row[index] || null;
                    break;
                  case 'VarianceinWT':
                    rowObject.varianceInWt = row[index] || null;
                    break;
                  case 'TypeofMaterial':
                    rowObject.typeOfMaterial = row[index] || null;
                    break;
                  default:
                    break;
                }
              });
              if(rowObject.invoiceNo != undefined && rowObject.invoiceNo != null && rowObject.invoiceNo != ''){
                return rowObject; // Return the populated DTO object
              }
            });
            
            setExcelData(rows); // Set the transformed data to state
            // console.log('excelData............',excelData)
          };
          reader.readAsArrayBuffer(file);
        }
      };
      
    

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = dprTripsSampleFile
        link.download = 'DPR_trips.xlsx';
        link.click();
      };
    
      const handleRemoveFile = () => {
        setFile(null);
      };

     const addDprFileData = async() =>{
        if(excelData.length > 0){
            if(newFile){
                var formData = new FormData()
                formData.append('file',newFile)
            }
            await Service.addUploadFileData(excelData).then(async(res) => {
                if (res.status == true) {
                    await  Service.addUploadFile(formData).then((response) => {
                        if(!response.status){
                            setAlertData({
                                open: true,
                                message: response.message,
                                severity: "error",
                              });
                              setSpinner(false);
                        }else{
                            setAlertData({
                                open: true,
                                message:res.message ,
                                severity: "success",
                              });
                              // window.location.reload(); 
                              setSpinner(false); 
                        }
                    })
                      
                } else {
                  setAlertData({
                    open: true,
                    message: res.message,
                    severity: "error",
                  });
                  setSpinner(false);
                }
              });
        }
       
        console.log('excelData....',excelData)
      }
    return (
        <>
            <Header />
            <div className="main_container">
                <div className="header_box">
                    <Typography
                        style={{
                            fontWeight: "bold",
                            fontSize: "17px",
                            fontFamily: "Roboto",
                        }}
                        className={classes.heading}
                    >
                        {/* Geofence Based Trips Reconciliation */}
                        On Demand Trips
                    </Typography>
                    <div className="header_buttons" style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                    {/* Sample File Button */}
                    {/* <Button className="cancel_button"
                        variant="contained"
                        onClick={()=>history.push('/uploadFileReconciliation')}
                        // startIcon={<GetAppIcon />}
                        style={{ width:'150px' }} // Set consistent height
                    >
                        Upload File
                    </Button> */}

                    {/* Upload File Button */}
                    {/* <Button
                        component="label"
                        className="save_button"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        style={{ width:'150px' }} // Set consistent height
                    >
                        Upload File
                        <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                        />
                    </Button> */}
                    {/* <pre>{JSON.stringify(excelData, null, 2)}</pre> */}

                    {/* Add Trips Button */}
                    

                    {/* Display the uploaded file name and remove icon below the buttons */}
                    {/* {newFile && (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '0.5rem' }}>
                        <Typography variant="body2">{newFile.name}</Typography>
                        <IconButton onClick={handleRemoveFile} aria-label="remove file">
                            <DeleteIcon color="secondary" />
                        </IconButton>
                        </div>
                        
                    )}
                    {newFile &&  <Button
                                                
                                                    variant="contained"
                                                    className="save_button "
                                                    onClick={addDprFileData}
                                                    startIcon={
                                                        spinner ? (
                                                            <CircularProgress size={20} color={"#fff"} />
                                                        ) : null
                                                    }
                                                >
                                                    Submit
                                                </Button>} */}
                                                <div>
                    {!newFile && <Button
                    disabled={isAddingTrips}
                        className={classes.client_button}
                        startIcon={
                          <AddCircleOutlinedIcon style={{ fontSize: "18px" }} />
                        }
                        onClick={addTripsFunction}
                        // startIcon={
                        // buttonSpinner ? <CircularProgress size={20} style={{ color: "#fff" }} /> : null
                        // }
                        // style={{ height: '50px' }} // Set consistent height
                    >
                        Add Trips
                    </Button>}
                   
                    {/* {showModal && 
                <div className="animation-container">
                <button disabled={isAddingTrips}>
                    {isAddingTrips ? "Adding Trips..." : "Add Trips"}
                </button>

                <div className="progress-bar">
                    <div className="road">
                    <div className="truck" style={{ left: `${progress}%` }}>
                        <div
                        className="percentage"
                        style={{
                            position: 'absolute',
                            top: '-30px',         // Positions the percentage above the truck
                            left: '50%',          // Centers it relative to the truck
                            transform: 'translateX(-50%)', // Centers above truck
                        }}
                        >
                        {progress}%
                        </div>
                    </div>
                    </div>
                </div>

                {progress === 100 && (
                    <div className="success-message">Trips Added Successfully!</div>
                )}
                </div>
                }    */}
             {showAnimation && (
//   <div className="animation-container">
//     <div className="progress-bar">
//       <div className="road">
//         <div className="truck" style={{ left: `${progress}%` }}>
//           <div className="percentage">
//             {progress}%
//           </div>
//         </div>
//       </div>
//     </div>
    
//   </div>
(<div style={styles.overlay}>
    <div style={styles.modal}>
        <strong style={styles.title}>Your trips are being added. Please wait. <span style={{color:'green'}}>({inProgress}/{totalTrips})</span></strong>

        <div style={styles.container}>
            {/* <span style={styles.startCount}>0</span> Start count */}
            <div style={styles.progressBar}>
                <div style={{ ...styles.progress, width: `${(inProgress / totalTrips) * 100}%` }} />
                <div
                    style={{
                        ...styles.truckIcon,
                        left: `${(inProgress / totalTrips) * 100}%`, // Move the truck icon based on progress
                    }}
                >
                    <img
                        src="https://img.icons8.com/ios-filled/50/000000/truck.png" alt="Truck" style={styles.truckImage}
                    />
                    <span style={styles.truckCount}>{inProgress}</span> {/* Show the in-progress trips count in front of the truck */}
                </div>
            </div>
            <span style={styles.endCount}>{totalTrips}</span> {/* End count */}
        </div>

        {/* {loadingComplete && <div style={styles.successMessage}>Trips Added Successfully!</div>} */}
        {(alertData.open) ? (
                            <div className={classes.alertBox}>
                                <AlertMessage
                                    severity={alertData.severity}
                                    message={alertData.message}
                                    closeAlert={closeAlert}
                                />
                            </div>
                        ) : null}
        <Button
        style={{width:100,marginTop:'10px'}}
                          variant="outlined"
                          className="cancel_button"
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
    </div>
</div>

)
)}


       </div>
      </div>
                </div>
                <div>
                    <Paper
                        style={{
                            margin: "10px",
                            fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                            className="details_container_content"
                            style={{ padding: "10px", margin: "10px" }}
                        >
                            <Grid item xs={2}>
                                <Fields.AntSelectableSearchField
                                    fieldData={vehicleTypeField.vehicleType}
                                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                                    varint="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <MultiselectFields
                                    fieldData={fields.vehicleslist}
                                    options={vehicles}
                                    selectChangeHandler={vehicleSelectHandler}
                                    mode={"multiple"}
                                />
                            </Grid>


                            <Grid item xs={2} className={"custom_select"}>
                                <Fields.AntSelectableSearchField
                                    fieldData={fields.clientId}
                                    autoCompleteChangeHandler={(value, name) =>
                                        autoCompleteChangeHandler(value, name)
                                    }
                                />
                            </Grid>

                            <Grid item xs={2} className={"custom_select"}>
                                <MultiselectFields
                                    fieldData={fields.routesList}
                                    options={routes}
                                    selectChangeHandler={routeSelectHandler}
                                    mode={"multiple"}
                                />
                            </Grid>

                            {/* <Grid item xs={2} className={"custom_select"}>
                                <Fields.AntSelectableSearchField
                                    fieldData={fields.routeId}
                                    autoCompleteChangeHandler={(value, name) =>
                                        autoCompleteChangeHandler(value, name)
                                    }
                                />
                            </Grid> */}

                            {/* <Grid item xs={3} style={{ marginTop: "28px" }}>
                                <DateRangePicker
                                    fieldData={fields.date}
                                    onDateSelect={handleDateRangeControl}
                                    value={dateRangeValue}
                                />
                            </Grid> */}
                            {/* <Grid item xs={2}>
                                <Fields.AntdDatePickerField
                                    fieldData={fields.date}
                                    timeStampChangeHandler={timeStampChangeHandler}
                                />
                            </Grid> */}

                            <Grid item xs={1}>
                                <Button
                                    style={{ marginTop: '27px' }}
                                    variant="contained"
                                    className="save_button"
                                    //   onClick={() => {
                                    //     if (props.location.state) {
                                    //     //   updateGroupHandler();
                                    //     } else {
                                    //       addGroupHandler();
                                    //     }
                                    //   }}
                                    onClick={onClickHandler}
                                    startIcon={
                                        spinner ? (
                                            <CircularProgress size={20} color={"#fff"} />
                                        ) : null
                                    }
                                >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                        {(!buttonSpinner && alertData.open) ? (
                            <div className={classes.alertBox}>
                                <AlertMessage
                                    severity={alertData.severity}
                                    message={alertData.message}
                                    closeAlert={closeAlert}
                                />
                            </div>
                        ) : null}
                    </Paper>
                    <div style={{ background: "#f4f4f4" }}>
                        {loader ? (
                            <CircularProgress
                                disableShrink
                                style={{ position: "fixed", top: "60%", left: "50%" }}
                            />
                        ) : (
                            <Components.SelectableTable
                                headRows={headRows}
                                // cols={cols}
                                tableData={tableData}
                                // pagination={pagination}
                                //   disableColumnFilter={true}
                                // pageChangeHandler={pageChangeHandler}
                                // rowsPerPageChangeHandler={rowsPerPageChangeHandler}
                                // total={total}
                                // columnCheckHandler={columnCheckHandler}
                                selected={selectedRows}
                                handleSelectAllClick={handleSelectAllClick}
                                handleClick={handleClick}
                                isTrips = {true}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default TripsViewData;
